<template>
    <div class="SkuDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" label-width="80px" width="auto" size="small">
                    <el-form-item label="名称">
                        <el-input readonly v-model="form.name" />
                    </el-form-item>
                    <el-form-item label="条码">
                        {{ form.bars.join(' / ') }}
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-input readonly :value="form.type | goodsType" />
                    </el-form-item>
                    <el-form-item label="规格">
                        <el-input readonly v-model="form.specs" />
                    </el-form-item>
                    <el-form-item label="类目">
                        <el-input readonly v-model="form.category" />
                    </el-form-item>
                    <el-form-item label="单位(小)">
                        <el-input readonly v-model="form.unit" />
                    </el-form-item>
                    <el-form-item label="品牌">
                        <el-input readonly v-model="form.brand" />
                    </el-form-item>
                    <el-form-item label="保质期">
                        <el-input readonly v-model="form.expirationDate" />
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'SkuDetail',
    props: ['form'],
    data() {
        return {
            meta: {},
        };
    },
    mounted() {},
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
